var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header--tabs" },
    [
      _c(
        "van-tabs",
        {
          attrs: { color: "#26a2ff" },
          on: { click: _vm.onClick },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        _vm._l(_vm.tabs, function (item, index) {
          return _c("van-tab", { key: index, attrs: { title: item.name } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }