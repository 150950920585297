<template>
  <div class="header--tabs">
    <van-tabs
      v-model="active"
      @click="onClick"
      color="#26a2ff"
    >
      <van-tab
        v-for="(item,index) of tabs"
        :title="item.name"
        :key="index"
      ></van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      tabs: [
        {
          name: '临时资料',
          path: 'linshiziliao'
        },
        {
          name: '案件档案',
          path: 'anjiandangan'
        },
        // {
        //   name: '文书模版',
        //   path: 'wenshumoban'
        // },
        {
          name: '我的知识',
          path: 'wodezhishi'
        },
        {
          name: '系统知识',
          path: 'xitongzhishi'
        }
      ]
    }
  },
  computed: {
    ...mapState('zhishishu', ['tabNum']),
    active: {
      get () {
        return this.tabNum
      },
      set (value) {
        this.setTabNum(value)
      }
    }
  },
  methods: {
    ...mapMutations('zhishishu', ['setTabNum']),
    onClick (index) {
      this.setTabNum(index)
    },
    jumpRouter () {
      const routerName = `casedetail-${this.tabs[this.tabNum].path}`
      const caseId = this.$route.params.caseId
      this.$router.push({ name: routerName, params: { caseId } })
    }
  },
  watch: {
    active () {
      this.jumpRouter()
    }
  },
  created () {
    this.jumpRouter()
  }
}
</script>
