var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "知识树", fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.goHistoryRoute },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c("tab-component"),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.zhishishu_loading,
              expression: "zhishishu_loading",
            },
          ],
          staticClass: "zhishishu_loading",
        },
        [_c("van-loading", { attrs: { type: "spinner" } })],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }