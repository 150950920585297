<template>
  <fb-page>
    <!-- header -->
    <fb-header slot="header" :title="'知识树'" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="goHistoryRoute"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
    </fb-header>
    <!-- body -->
    <tab-component />
    <div class="zhishishu_loading" v-show="zhishishu_loading">
      <van-loading type="spinner" />
    </div>
    <router-view></router-view>
  </fb-page>
</template>
<script>
import TabComponent from './components/Tabs'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    TabComponent,
  },
  computed: {
    ...mapState('zhishishu', ['zhishishu_loading']),
  },
  methods: {
    ...mapMutations('zhishishu', ['setTabNum']),
    goHistoryRoute() {
      // 目前是两种 一种是原生进入 还有一种是web案件详情进入
      const { caseId } = this.$route.params
      if (caseId !== 'ios&android') {
        this.$router.push({ name: 'casedetail-timeline' })
      } else {
        // /index#/casedetail/zss/ios&android
        try {
          const u = navigator.userAgent
          if (u === 'fb-Android-Webview') {
            // 安卓环境
            // eslint-disable-next-line
            AND2JS.finishKnowledgeTreePage()
          } else if (u === 'fb-iOS-Webview') {
            // ios环境
            // eslint-disable-next-line
            window.webkit.messageHandlers.backToTool.postMessage({})
          } else if (u === 'fb-flutter-Webview') {
            // eslint-disable-next-line
            window.backToTool.postMessage('{}')
          } else {
            this.$toast('非原生环境,返回原生指定页')
          }
        } catch (err) {
          console.log('backToTool.postMessage', err)
        }
      }
    },
  },
  destroyed() {
    this.setTabNum(3)
  },
}
</script>

<style lang="stylus">
.zhishishu_loading {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0);
  z-index: 1000;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
}
</style>
